import React from 'react';

import { DiMongodb, DiMysql } from 'react-icons/di';
import {
	FaAws,
	FaCss3Alt,
	FaDocker,
	FaGit,
	FaGithub,
	FaHtml5,
	FaJenkins,
	FaJs,
	FaLinkedin,
	FaNodeJs,
	FaPython,
	FaReact,
	FaSass,
} from 'react-icons/fa';
import {
	HiOutlineCode,
	HiOutlineLightningBolt,
	HiOutlineMail,
	HiOutlineUser,
} from 'react-icons/hi';
import {
	SiDjango,
	SiExpress,
	SiNextdotjs,
	SiRedux,
	SiTailwindcss,
	SiTypescript,
} from 'react-icons/si';

export const navigations = [
	{
		name: 'About',
		url: '#about',
		icon: <HiOutlineUser className="w-5 h-5" />,
	},
	{
		name: 'Experience',
		url: '#services',
		icon: <HiOutlineLightningBolt className="w-5 h-5" />,
	},
	{
		name: 'Projects',
		url: '#work',
		icon: <HiOutlineCode className="w-5 h-5" />,
	},
	{
		name: 'Contact',
		url: '#contact',
		icon: <HiOutlineMail className="w-5 h-5" />,
	},
];

export const footerData = [
	{
		headerTitle: 'Links',
		links: [
			{
				name: 'Github',
				url: 'https://github.com/G-Linares',
				isLink: true,
			},
			{
				name: 'LinkedIn',
				url: 'https://www.linkedin.com/in/gerardo-linares/',
				isLink: true,
			},
		],
	},
	{
		headerTitle: 'Contact Info',
		links: [
			{
				name: 'Phone: (777)5601025',
				url: 'Link',
				isLink: false,
			},
			{
				name: 'Morelos,',
				url: 'Link',
				isLink: false,
			},
			{
				name: 'Mexico',
				url: 'Link',
				isLink: false,
			},
			{
				name: 'gerrylinares@gmail.com',
				url: 'Link',
				isLink: false,
			},
		],
	},
];

export const pastJobs = [
	{
		title: 'FoxTrot Dev',
		description: 'Software Engineer',
		years: 'January 2017 - Oct. 2017',
		icon: 'https://ik.imagekit.io/glinares/portfolio/foxtrot.png?updatedAt=1697492372345',
	},
	{
		title: 'Dish',
		description: 'QA Engineer',
		years: 'Oct. 2017 - April 2020',
		icon: 'https://ik.imagekit.io/glinares/portfolio/dish.png?updatedAt=1697490835599',
	},
	{
		title: 'Keywords Studios',
		description: 'Player Support Agent',
		years: 'April 2020 - Nov. 2020',
		icon: 'https://ik.imagekit.io/glinares/portfolio/keywords.png?updatedAt=1697490835932',
	},
	{
		title: 'Cura Deuda',
		description: 'Software Developer',
		years: 'Nov. 2020 - Oct. 2021',
		icon: 'https://ik.imagekit.io/glinares/portfolio/curaDeuda.png?updatedAt=1697490835618',
	},
	{
		title: 'Altimetrik',
		description: 'Software Developer',
		years: 'Oct. 2021 - Sept. 2023',
		icon: 'https://ik.imagekit.io/glinares/portfolio/altimetrik.png?updatedAt=1697511108227',
	},
	{
		title: 'Envato',
		description: 'Sr. Software Developer',
		years: 'Sept. 2023 - Present',
		icon: 'https://ik.imagekit.io/glinares/portfolio/envato.png?updatedAt=1697492627888',
	},
];

export const socialLinks = [
	{
		url: 'https://github.com/G-Linares',
		icon: <FaGithub className="md:w-10 md:h-10 h-7 w-7" />,
	},
	{
		url: 'https://www.linkedin.com/in/gerardo-linares/',
		icon: <FaLinkedin className="md:w-10 md:h-10 h-7 w-7" />,
	},
];

export const technologies = [
	{
		name: 'React JS',
		icon: (
			<FaReact className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'Next JS',
		icon: (
			<SiNextdotjs className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'Redux',
		icon: (
			<SiRedux className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'JavaScript',
		icon: (
			<FaJs className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'Typescript',
		icon: (
			<SiTypescript className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'Node JS',
		icon: (
			<FaNodeJs className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'Express',
		icon: (
			<SiExpress className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'HTML',
		icon: (
			<FaHtml5 className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'CSS',
		icon: (
			<FaCss3Alt className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'Sass',
		icon: (
			<FaSass className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'TailwindCss',
		icon: (
			<SiTailwindcss className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'Python',
		icon: (
			<FaPython className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'DJango',
		icon: (
			<SiDjango className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'Git',
		icon: (
			<FaGit className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'Docker',
		icon: (
			<FaDocker className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'MySQL',
		icon: (
			<DiMysql className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'MongoDB',
		icon: (
			<DiMongodb className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'Jenkins',
		icon: (
			<FaJenkins className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
	{
		name: 'AWS',
		icon: (
			<FaAws className="w-full h-full rounded-full cursor-pointer text-primary group-hover:text-white" />
		),
	},
];

export const projectCategories = [
	{
		name: 'React JS',
		slug: 'react-js',
	},
	{
		name: 'Typescript',
		slug: 'typescript',
	},
	{
		name: 'Tailwind CSS',
		slug: 'tailwind-css',
	},
	{
		name: 'Node JS',
		slug: 'node-js',
	},
	{
		name: 'MERN Stack',
		slug: 'mern-stack',
	},
	{
		name: 'Next JS',
		slug: 'next',
	},
];

export const projects = [
	{
		thumbnail:
			'https://ik.imagekit.io/glinares/portfolio/discord%20clone.png?updatedAt=1697493365681',
		title: 'Discord Clone',
		description:
			'Working Discord clone where you can create, edit and delete channel servers. As as well as to chat with voice and video with other users in different server channels.',
		status: 2,
		categories: [
			projectCategories[1],
			projectCategories[2],
			projectCategories[5],
		],
		demoURL: 'https://next-discord-clone-production-7152.up.railway.app/',
		codeURL: 'https://github.com/G-Linares/next-discord-clone',
	},
	{
		thumbnail:
			'https://ik.imagekit.io/glinares/portfolio/gym.png?updatedAt=1697493728769',
		title: 'Gym App',
		description:
			'Handy app for the gym. This tool helps the user understand more about gym exercises and muscles. It also suggests youtube videos related to that exercise.',
		status: 2,
		categories: [
			projectCategories[3],
			projectCategories[0],
			projectCategories[4],
		],
		demoURL: 'https://gymexamples.netlify.app/',
		codeURL: 'https://github.com/G-Linares/gym',
	},
	{
		thumbnail:
			'https://ik.imagekit.io/glinares/portfolio/note-tion.png?updatedAt=1697494288932',
		title: 'Note-tion',
		description:
			'Notion clone app where users can create an account and start writing posts with multiple tools and publish them using multiple notion tools.',
		status: 2,
		categories: [
			projectCategories[1],
			projectCategories[2],
			projectCategories[5],
		],
		demoURL: '#demo',
		codeURL: 'https://github.com/G-Linares/note-tion',
	},
	{
		thumbnail:
			'https://ik.imagekit.io/glinares/portfolio/dashboard.png?updatedAt=1697494723207',
		title: 'Admin Dashboard',
		description:
			'This app is an admin dashboard where users can visualize all information easily with multiple charts and graphs. This app can be scalated to show any type of information.',
		status: 2,
		categories: [
			,
			projectCategories[3],
			projectCategories[4],
			projectCategories[0],
		],
		demoURL: 'https://github.com/G-Linares/chart-js-dashboard',
		codeURL: 'https://github.com/G-Linares/chart-js-dashboard',
	},
	{
		thumbnail:
			'https://ik.imagekit.io/glinares/portfolio/adminEcommerce.png?updatedAt=1697511235330',
		title: 'Ecommerce Admin',
		description:
			'Admin app for e-commerce where you can manage stock, add, edit and remove products, create categories and add banners that are going to be showed in the client side of the app.',
		status: 2,
		categories: [
			projectCategories[1],
			projectCategories[2],
			projectCategories[5],
		],
		demoURL: 'https://ecommerce-admin-gl.vercel.app/',
		codeURL: 'https://github.com/G-Linares/dashboard-admin',
	},
	{
		thumbnail:
			'https://ik.imagekit.io/glinares/portfolio/clientEcommerce.png?updatedAt=1697511220543',
		title: 'Ecommerce Client',
		description:
			'Client side of the E-commerce, this app contains multiple grids and multiple sorting functions where the user can visualize all of the products, add them to a cart and make a purchase.',
		status: 2,
		categories: [
			projectCategories[1],
			projectCategories[2],
			projectCategories[5],
		],
		demoURL: 'https://ecommerce-client-gl.vercel.app/',
		codeURL: 'https://github.com/G-Linares/dashboard-client',
	},
	{
		thumbnail:
			'https://ik.imagekit.io/glinares/portfolio/similarity.png?updatedAt=1697511853009',
		title: 'Similarity',
		description:
			'This is a project to compare documents and find similarity between them. The app includes a front end where you can manage API keys and see requests. It uses an AI model to do calculations.',
		status: 1,
		categories: [
			projectCategories[1],
			projectCategories[2],
			projectCategories[5],
		],
		demoURL: 'https://similarity-ts.vercel.app/documentation',
		codeURL: 'https://github.com/G-Linares/similarity-ts',
	},
	{
		thumbnail:
			'https://ik.imagekit.io/glinares/portfolio/blog.png?updatedAt=1697512214833',
		title: 'Markdown Blog',
		description:
			'Project where I can upload my Markdown notes and displayed them as a Blog. One big feature is the complex filtering method that uses categories as indexes. The user can only visualize posts.',
		status: 2,
		categories: [
			projectCategories[0],
			projectCategories[1],
			projectCategories[2],
		],
		demoURL: 'https://markdownbloggl.vercel.app/',
		codeURL: 'https://github.com/G-Linares/next-md-blog',
	},
];
