import React, { useRef } from 'react';

import { useGLTF } from '@react-three/drei';

export default function Computer({ ...props }) {
	const group = useRef();
	const { nodes, materials } = useGLTF('/computer.gltf');
	return (
		<group ref={group} {...props} dispose={null}>
			<group rotation={[-Math.PI / 2, 0, 0]} scale={0.36}>
				<group rotation={[Math.PI / 2, 0, 0]}>
					<group position={[0, 0, -0.03]} rotation={[0.09, 0, 0]}>
						<mesh
							geometry={nodes.Object_4.geometry}
							material={materials.TextureGrid}
						/>
					</group>
					<group position={[0, 0.5, 0.03]} rotation={[0.09, 0, 0]}>
						<mesh
							geometry={nodes.Object_6.geometry}
							material={materials.TextureGrid}
						/>
					</group>
					<group position={[0.88, 0.06, 0.75]} rotation={[0.09, 0, 0]}>
						<mesh
							geometry={nodes.Object_8.geometry}
							material={materials.TextureGrid}
						/>
					</group>
					<group position={[0, 0.06, 0.81]} rotation={[0.09, 0, 0]}>
						<mesh
							geometry={nodes.Object_10.geometry}
							material={materials.TextureGrid}
						/>
					</group>
				</group>
			</group>
		</group>
	);
}

useGLTF.preload('/computer.gltf');
