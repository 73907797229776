import React from 'react';

import { technologies } from '../lib/constants';

const Technologies = () => {
	return (
		<section
			id="technologies"
			className="bg-paper/80 dark:bg-paperDark/80 py-12 px-10 body-font"
		>
			<div className=" w-full md:w-[60%] mx-auto mb-10 text-center">
				<h2 className="text-3xl font-bold mb-3">- Technologies -</h2>
				<p className="text-lg w-[80%] mx-auto text-textSecondary dark:text-textDarkSecondary font-medium">
					I feel confident working with the following technologies.
				</p>
			</div>
			<div className="mx-auto flex md:flex-row-reverse flex-col-reverse justify-center items-center">
				<div className="flex justify-center items-center flex-wrap max-w-[420px] m-auto">
					{technologies.map(({ icon }, index) => (
						<div
							key={index}
							className="border-[3px] cursor-pointer transition duration-300 border-primary p-2 m-2 rounded-full group hover:text-primary group-hover:text-white hover:bg-primary"
						>
							<div className="w-[35px] h-[35px] ">{icon}</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Technologies;
