import React from 'react';

import Section from './Partials/Section';
import ServiceCard from './Partials/ServiceCard';

import { pastJobs } from '../lib/constants';

const Services = () => {
	return (
		<Section
			id="services"
			title="- Experience -"
			description="Throughout my career as a developer I have worked or colaborated with the following companies."
			subtitle="My Previous Employment History"
		>
			<div className="flex flex-wrap justify-center items-center">
				{pastJobs.map(({ title, description, icon, years }) => (
					<ServiceCard
						key={title}
						icon={icon}
						title={title}
						description={description}
						years={years}
					/>
				))}
			</div>
		</Section>
	);
};

export default Services;
