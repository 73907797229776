import React from 'react';

//this uses the Section created for the Hero
import Section from './Partials/Section';
import { socialLinks } from '../lib/constants';

const About = () => {
	return (
		<Section id="about" className="!relative">
			<div className="flex sm:flex-row justify-center flex-col px-4 text-center">
				<div className="w-full sm:w-1/2">
					<h1 className="capitalize title-font sm:text-3xl text-2xl mb-2 font-semibold">
						Connect With Me
					</h1>
					<p className="leading-relaxed text-textPrimary  dark:text-textDarkSecondary text-medium opacity-70">
						I am one message away on every social media platform. You can also
						send me an email anytime, I will reply within 24 hours.
					</p>
					<div className="select-none capitalize flex my-4 justify-center">
						{socialLinks.map(({ icon, url }) => (
							<a href={url} key={url} target="_blank" rel="noopener">
								<div className="border-primary border-2 mr-3 mb-3 rounded-full p-2 text-primary hover:bg-primary hover:text-white transition duration-300 cursor-pointer">
									{icon}
								</div>
							</a>
						))}
					</div>
				</div>
			</div>
		</Section>
	);
};

export default About;
