import React from 'react';

import About from '../Components/About';
import Hero from '../Components/Hero';
import Services from '../Components/Services';
import Technologies from '../Components/Technologies';
import Work from '../Components/Work';
import Contact from '../Components/Contact';

export default function Home() {
	return (
		<div className="bg-[url(https://ik.imagekit.io/glinares/portfolio/bg.jpg?updatedAt=1697490828918)] bg-fixed bg-cover bg-no-repeat bg-center">
			<Hero />
			<About />
			<Services />
			<Work />
			<Technologies />
			<Contact />
		</div>
	);
}
