import React, { useState, useEffect } from 'react';

import { CgTerminal } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { HiChevronUp, HiOutlineSun, HiOutlineMoon } from 'react-icons/hi';

import { navigations } from '../lib/constants';
import { useIsRootLocation } from '../lib/utils';

const Navbar = () => {
	const isRoot = useIsRootLocation();
	const navigate = useNavigate();

	// this state will controll which button is active
	const [active, setActive] = useState('');
	// this state will control when the "Scroll to top button will be showed"
	const [showButton, setShowButton] = useState(false);
	// this state will control the dark mode
	const [theme, setTheme] = useState('light');
	//this will be a bar that will slowly grow depending on the percentage of the page scrolled
	const onScroll = () => {
		let pixelsFromTop = window.scrollY;
		let documentHeight = document.body.clientHeight;
		let windowHeight = window.innerHeight;
		let difference = documentHeight - windowHeight;
		let percentage = (100 * pixelsFromTop) / difference;
		const headerBar = document.getElementById('header-bar') as HTMLElement;
		headerBar.style.width = `${percentage}%`;
		window.scrollY > 1 ? setShowButton(true) : setShowButton(false);
	};

	//this triggers the bar percentage animation
	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	});

	const handleScroll = (url: string) => {
		if (!isRoot) {
			return navigate('/');
		}
		setActive(url);
		document
			.querySelector(url)!
			.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	// This will change the theme from light to dark
	const handleChangeTheme = () => {
		const bodyElement = document.body as HTMLElement;
		if (theme === 'light') {
			setTheme('dark');
			bodyElement.classList.add('dark');
		} else {
			setTheme('light');
			bodyElement.classList.remove('dark');
		}
	};

	return (
		<>
			<header
				className={`backdrop-blur-sm bg-paper/70 dark:bg-paperDark/80 body-font sticky top-0 w-full z-50 transition-shadow duration-800 shadow-sm`}
			>
				<div className="max-w-[1140px] mx-auto dark:text-textDarkPrimary text-textPrimary">
					<div className="mx-auto flex flex-wrap p-2.5 items-center flex-col xs:flex-row">
						<div
							onClick={() => handleScroll('#top')}
							className="flex title-font font-medium items-center justify-items-center text-textPrimary dark:text-textDarkPrimary cursor-pointer"
						>
							<CgTerminal width={36} height={36} />
							<span className="ml-2 text-lg md:text-xl">G. Linares</span>
						</div>
						<nav className="mt-3 xs:mt-0 xs:!ml-auto flex flex-wrap items-center text-base justify-center">
							{navigations.map(({ name, url, icon }) => (
								<button
									type="button"
									key={url}
									onClick={() => handleScroll(url)}
									className={` hover:bg-black/5 bg-transparent font-medium outline-none rounded-lg text-sm p-1 px-1.5 m-1 md:px-4 md:py-2  flex  ${
										active == url
											? 'bg-primary/90 !text-white hover:!bg-primary'
											: ''
									} dark:text-white  dark:hover:bg-white/5`}
								>
									{icon}
									<div className="pl-1.5 hidden md:block stroke-[2.5px]">
										{name}
									</div>
								</button>
							))}
							<button
								type="button"
								onClick={handleChangeTheme}
								className={`hover:bg-black/5 outline-none font-medium rounded-lg text-sm p-1 px-1.5 m-1 md:px-4 md:py-2 flex`}
							>
								{theme == 'light' ? (
									<HiOutlineMoon className="w-5 h-5 dark:text-white  dark:hover:bg-white/5" />
								) : (
									<HiOutlineSun className="w-5 h-5 stroke-[2.25px]" />
								)}
							</button>
						</nav>
					</div>
				</div>
				<div id="header-bar" className="border-b-[2px] border-primary w-0" />
			</header>
			<button
				type="button"
				onClick={() => handleScroll('#top')}
				className={`dark:text-textDarkPrimary scale-1 fixed bottom-5 right-5 z-[6000] shadow-2xl outline-none font-medium rounded-full text-sm p-2.5 m-1 flex bg-primary/90 text-white hover:!bg-primary ${
					!showButton && 'scale-0'
				}`}
			>
				<HiChevronUp className="w-5 h-5" />
			</button>
		</>
	);
};

export default Navbar;
