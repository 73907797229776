import React from 'react';
import { CgTerminal } from 'react-icons/cg';

import { footerData } from '../lib/constants';

const Footer = () => {
	return (
		<footer className="relative overflow-hidden z-10 dark:text-textDarkSecondary text-textSecondary bg-paper dark:bg-paperDark shadow-normal">
			<div className="container max-w-[1140px] px-5 py-12 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
				<div className="w-64 mb-10 md:mb-0 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
					<a className="flex items-center md:justify-start justify-center dark:text-white text-gray-800">
						<CgTerminal width={36} height={36} />
						<span className="ml-3 text-2xl font-semibold">G. Linares</span>
					</a>
					<p className="mt-2 text-md text-textSecondary opacity-80">
						Full-Stack Software Engineer @ Envato, located in Morelos, Mexico.
					</p>
				</div>
				<div className="flex-grow flex flex-wrap justify-end md:pl-20 -mb-10 md:text-left text-center flex-1">
					{footerData.map((item) => (
						<div
							key={item.headerTitle}
							className="lg:w-1/4 md:w-1/2 w-full px-4"
						>
							<h2 className="title-font font-medium dark:text-white capitalize text-gray-800 tracking-wider text-sm mb-3">
								{item.headerTitle}
							</h2>
							<nav className="list-none mb-10">
								{item.links.map(({ name, url, isLink }, idx) => (
									<li key={idx}>
										{isLink ? (
											<a href={url} target="_blank" rel="noopener">
												<div className="text-gray-400 text-sm hover:text-gray-800 dark:hover:text-white cursor-pointer">
													{name}
												</div>
											</a>
										) : (
											<div className="text-gray-400 text-sm">{name}</div>
										)}
									</li>
								))}
							</nav>
						</div>
					))}
				</div>
			</div>

			<div className="max-w-[1140px] bg-opacity-75 z-30 mx-auto">
				<div className="container mx-auto py-4 px-5 flex flex-wrap flex-col justify-center capitalize md:font-semibold sm:flex-row">
					<p className="text-gray-400 text-sm text-center flex items-center justify-center">
						Created With <span className="text-red-600 text-lg mx-1.5">♥</span>{' '}
						by Gerardo Linares
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
