import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import { motion } from 'framer-motion';

import Section from './Partials/Section';
import WorkCard from './Partials/WorkCard';
import { projectCategories, projects } from '../lib/constants';

const Work = () => {
	const [category, setCategory] = useState('all');
	const [filteredWorks, setFilteredWorks] = useState(projects);
	const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

	//this will filter all the prokects I've worked on
	const handleWorkFilter = (category: string) => {
		setCategory(category);
		setAnimateCard({ y: 100, opacity: 0 });
		setTimeout(() => {
			setAnimateCard({ y: 0, opacity: 1 });

			if (category === 'all') {
				setFilteredWorks(projects);
			} else {
				let workList: any = [];
				projects.forEach((work) => {
					let workCategories: any = [];
					work.categories.forEach(
						(category: { name: string; slug: string } | undefined) =>
							workCategories.push(category?.slug)
					);
					if (workCategories.includes(category)) {
						workList.push(work);
					}
				});
				setFilteredWorks(workList);
			}
		}, 400);
	};

	// changing the name to Projects section
	return (
		<Section
			id="work"
			title="- Projects -"
			description="Here you will find information and a demo of my personal projects that I've been working in the last 3 years"
			subtitle="All my personal projects from the last 3 years"
			className="!pb-14 md:!pb-28"
		>
			<div className="flex items-center m-auto flex-col text-center w-full mb-10">
				<ul className="flex flex-wrap justify-center text-sm font-medium text-center">
					{[{ name: 'All', slug: 'all' }, ...projectCategories].map(
						(ct, index) => (
							<li className="m-1 mb-1" key={index}>
								<div
									onClick={() => {
										handleWorkFilter(ct.slug);
									}}
									className={`inline-block shadow-normal py-2.5 px-5 cursor-pointer rounded-full transition-all duration-300 text-textPrimary dark:text-textDarkPrimary bg-paper dark:bg-paperDark hover:bg-primary hover:text-white dark:hover:bg-primary dark:hover:text-white  ${
										ct.slug == category && '!text-white !bg-primary'
									}`}
								>
									{ct.name}
								</div>
							</li>
						)
					)}
				</ul>
			</div>

			<Masonry
				breakpointCols={{ default: 4, 1100: 3, 700: 2, 500: 1 }}
				className="flex flex-wrap"
			>
				{filteredWorks.map((work: any, index) => (
					<motion.div
						key={index}
						animate={animateCard}
						transition={{ duration: 0.4, delayChildren: 0.4 }}
					>
						<WorkCard {...work} />
					</motion.div>
				))}
			</Masonry>
		</Section>
	);
};

export default Work;
