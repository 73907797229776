import React, { lazy, Suspense, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';

import Home from './views/Home';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';

// lazy loading for routes that are not rendered on main view
const NotFound = lazy(() => import('./views/NotFound'));

const App = () => {
	const [loading, setLoading] = useState(true);
	const spinner = document.getElementById('spinner');

	if (spinner) {
		setTimeout(() => {
			spinner.style.display = 'none';
			setLoading(false);
		}, 1500);
	}

	return !loading ? (
		<BrowserRouter>
			<div id="top" />
			<div
				className="relative flex flex-col min-h-screen bg-default dark:bg-defaultDark text-textPrimary dark:text-textDarkPrimary"
				id="app-wrapper"
			>
				<Navbar />
				<Suspense
					fallback={
						<div className="h-screen flex items-center justify-center">
							<PulseLoader color="#5e7eff" />
						</div>
					}
				>
					<Routes>
						<Route path="*" element={<NotFound />} />
						<Route path="/" element={<Home />} />
					</Routes>
				</Suspense>
				<Footer />
			</div>
		</BrowserRouter>
	) : null;
};

export default App;
